import React from 'react'

import {
  MdInsertChart,
  MdModeEditOutline,
  MdLocalActivity,
  MdEco
} from 'react-icons/md'
import { GiStairsGoal, GiModernCity } from 'react-icons/gi'
import { MdOutlineArchitecture, MdConstruction } from 'react-icons/md'
import { HiOutlineLightBulb } from 'react-icons/hi'
import { BsFillPeopleFill, BsBarChartSteps } from 'react-icons/bs'
import { AiOutlineCheckCircle } from 'react-icons/ai'
import { FaParking } from 'react-icons/fa'

const icons = {
  MdInsertChart,
  GiStairsGoal,
  AiOutlineCheckCircle,
  MdOutlineArchitecture,
  HiOutlineLightBulb,
  BsFillPeopleFill,
  BsBarChartSteps,
  GiModernCity,
  MdLocalActivity,
  MdConstruction,
  FaParking,
  MdEco,
  MdModeEditOutline
}

const RatingIcons = React.forwardRef(({ iconName, ...props }, ref) => {
  const Icon = icons[iconName]

  return (
    <div ref={ref}>
      <Icon {...props} />
    </div>
  )
})

export default RatingIcons
