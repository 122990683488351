import React from 'react'
import { connect } from 'react-redux'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import IconButton from '@material-ui/core/IconButton'
import DeleteIcon from '@material-ui/icons/Delete'
import Button from '../inputs/Button'
import { addToDataBase, deleteFromDataBase } from '../redux/actions/dataBase'

function DeleteRatingCriteriaAlert({ deleteFromDataBase, criteriaItem }) {
  const [open, setOpen] = React.useState(false)

  const openModal = () => setOpen(true)

  const closeModal = () => setOpen(false)

  const deleteRatingCriteria = () =>
    deleteFromDataBase('RatingCriteria', criteriaItem.id)

  return (
    <div>
      <IconButton onClick={openModal} size="small" aria-label="Delete">
        <DeleteIcon fontSize="small" style={{ color: '#f44336' }} />
      </IconButton>
      <Dialog
        open={open}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          Remove rating criteria.
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Remove
            <b className="px-1">{criteriaItem.label}</b>
            criteria ?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            size="md"
            variant="outlined"
            color="primary"
            onClick={closeModal}
          >
            Cancel
          </Button>
          <Button
            size="md"
            variant="contained"
            color="primary"
            onClick={deleteRatingCriteria}
            autoFocus
          >
            Remove
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}

const mapDispatchToProps = dispatch => {
  return {
    addToDataBase: (coll, doc, data) =>
      dispatch(addToDataBase(coll, doc, data)),
    deleteFromDataBase: (coll, doc) => dispatch(deleteFromDataBase(coll, doc))
  }
}

export default connect(null, mapDispatchToProps)(DeleteRatingCriteriaAlert)
