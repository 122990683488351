import HelsinkiLogo from './assets/icons/helsinki-logo.png'

export const logo = {
  src: HelsinkiLogo,
  alt: 'Helsinki logo'
}

export const competitionName = 'Meri-Rastilan monitoimitalo'

export const infoTableHeaders = [
  { header: 'Bruttoala brm2', accessor: 'bruttoala' },
  { header: 'Hyötyala hym2', accessor: 'hyötyala' }
]

export const settingsTabs = [
  { id: 'settings-users', label: 'Users', value: 'users' },
  { id: 'settings-submit-setup', label: 'Submit setup', value: 'submit-setup' },
  { id: 'rating-criteria', label: 'Rating criteria', value: 'rating-criteria' },
  { id: 'settings-tab-setup', label: 'Tab setup', value: 'tab-setup' },
  { id: 'settings-theme', label: 'Theme', value: 'theme' }
]

export const adminRoles = ['admin', 'secretary']

export const normalRoles = ['judge', 'specialist']
