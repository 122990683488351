import * as React from 'react'
import styled from 'styled-components/macro'
import { useTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import axios from 'axios'
import { MdEdit, MdClose } from 'react-icons/md'
import { Dialog } from '@reach/dialog'
import '@reach/dialog/styles.css'

import Icon from './components/Icon'
import FlexWrapper from './components/FlexWrapper'
import useFirestore from './hooks/useFirestore'
import useCollection from './hooks/useCollection'

const initialModalPosition = {
  x: 0,
  y: 0,
  z: 0,
  scale: 1,
  rotationX: 0,
  rotationY: 0,
  rotationZ: 0
}

function Edit3DModel({ xdTwinSecret }) {
  const { updateDocument } = useFirestore('info')
  const { documents } = useCollection('info')
  const [showDialog, setShowDialog] = React.useState(false)
  const [xdProject, setXdProject] = React.useState({
    name: '',
    model3DS: [],
    viewpoints: [],
    thumbnail: { url: '', name: '' },
    treeData: []
  })
  const [modalPosition, setModalPosition] = React.useState(initialModalPosition)
  const { t } = useTranslation('proposals')

  const open = () => setShowDialog(true)

  const close = async () => {
    setShowDialog(false)
    await updateDocument('modalPosition', { modalPosition })
  }

  const handleChangeModalPosition = event => {
    const { id, value } = event.target
    setModalPosition({ ...modalPosition, [id]: value })
  }

  React.useEffect(() => {
    if (!!xdTwinSecret.apiKey) {
      const config = {
        headers: {
          Authorization: `Bearer ${xdTwinSecret.jwt}`
        }
      }
      const getProject = async () => {
        const { data } = await axios(
          `https://prod-api.xd-twin.io/projects/637c7dc52d74ee0533bfa35e`,
          config
        )
        // console.log('PROJECT DATA:', data)
        setXdProject({
          id: data.id,
          name: data.name,
          model3DS: data.model3DS,
          viewpoints: data.viewpoints,
          thumbnail: data.thumbnail,
          treeData: data.treeData
        })
      }
      getProject()
    }

    return () => {
      setXdProject({})
    }
  }, [xdTwinSecret])

  React.useEffect(() => {
    if (!!documents && !!documents?.modalPosition?.modalPosition?.x) {
      setModalPosition(documents?.modalPosition?.modalPosition)
    } else {
      setModalPosition(initialModalPosition)
    }
  }, [documents])

  return (
    <>
      <Icon width="25px" height="25px" mr="0" ml="15px">
        <MdEdit onClick={open} />
      </Icon>

      <Dialog
        isOpen={showDialog}
        onDismiss={close}
        aria-label={t('Edit 3D model data')}
        style={{ boxSizing: 'border-box' }}
      >
        <div style={{ marginBottom: 24 }}>
          <FlexWrapper justifyContent="space-between" alignItems="center">
            <header>
              <h2 style={{ marginBottom: 5 }}>
                {t('City model settings for xD Twin submit template model')}
              </h2>

              <i style={{ color: '#3f51b5' }}>
                * {t('Close the modal to save your changes')}
              </i>
            </header>

            <Icon width="20px" height="20px" mr="0">
              <MdClose onClick={close} />
            </Icon>
          </FlexWrapper>
        </div>
        <Wrapper>
          <div style={{ marginBottom: 20 }}>
            <p>{t('Project name')}</p>
            <StyledInput
              value={xdProject?.name}
              onChange={event => console.log(event.target.value)}
              id="xd-project-name"
              name="xd-project-name"
              placeholder=""
            />
          </div>

          <div
            style={{
              marginBottom: 20
            }}
          >
            <p>{t('Element visibility')}</p>
            <div
              style={{
                padding: '10px',
                backgroundColor: 'white'
              }}
            >
              {xdProject?.treeData?.map((item, index) => (
                <div key={item.key}>
                  <input type="checkbox" id={item.key} name={item.title} />
                  <label htmlFor={item.title} style={{ marginLeft: 10 }}>
                    {item.title}
                  </label>
                </div>
              ))}
            </div>
          </div>

          <div style={{ marginBottom: 20 }}>
            <p>{t('Camera views')}</p>
            <img
              src={xdProject?.thumbnail?.url}
              alt={xdProject?.thumbnail?.name || 'Camera view'}
              style={{ width: 250 }}
            />
          </div>

          <div style={{ marginBottom: 20 }}>
            <p>{t('Model transform, scale and rotation')}</p>
            <div style={{ display: 'flex', gap: 30, flexWrap: 'wrap' }}>
              <div
                style={{
                  padding: '10px',
                  backgroundColor: 'white',
                  minWidth: 300
                }}
              >
                <p>{t('Edit position')}</p>
                <InputField
                  id="x"
                  label="X: "
                  name="x"
                  placeholder="Position X"
                  value={modalPosition.x}
                  onChange={handleChangeModalPosition}
                />
                <InputField
                  id="y"
                  label="Y: "
                  name="y"
                  placeholder="Position Y"
                  value={modalPosition.y}
                  onChange={handleChangeModalPosition}
                />
                <InputField
                  id="z"
                  label="Z: "
                  name="z"
                  placeholder="Position Z"
                  value={modalPosition.z}
                  onChange={handleChangeModalPosition}
                />
                <InputField
                  id="scale"
                  label="Scale: "
                  name="scale"
                  placeholder="Scale"
                  value={modalPosition.scale}
                  onChange={handleChangeModalPosition}
                />
              </div>

              <div
                style={{
                  padding: '10px',
                  backgroundColor: 'white',
                  minWidth: 300
                }}
              >
                <p>{t('Rotation')}</p>
                <InputField
                  id="rotationX"
                  label="X: "
                  name="rotationX"
                  placeholder="Rotation X"
                  value={modalPosition.rotationX}
                  onChange={handleChangeModalPosition}
                />
                <InputField
                  id="rotationY"
                  label="Y: "
                  name="rotationY"
                  placeholder="Rotation Y"
                  value={modalPosition.rotationY}
                  onChange={handleChangeModalPosition}
                />
                <InputField
                  id="rotationZ"
                  label="Z: "
                  name="rotationZ"
                  placeholder="Rotation Z"
                  value={modalPosition.rotationZ}
                  onChange={handleChangeModalPosition}
                />
              </div>
            </div>
          </div>
        </Wrapper>
      </Dialog>
    </>
  )
}

export default connect(state => ({
  xdTwinSecret: state.proposals.xdTwinSecret
}))(Edit3DModel)

function InputField({ label, value, onChange, id, name, placeholder }) {
  return (
    <div style={{ display: 'flex', alignItems: 'center', marginBottom: 20 }}>
      <label style={{ minWidth: 50 }}>{label}</label>
      <StyledInput
        value={value}
        onChange={onChange}
        id={id}
        name={name}
        placeholder={placeholder}
        style={{ border: '1px solid #ccc', marginLeft: 10, textAlign: 'right' }}
      />
    </div>
  )
}

const Wrapper = styled.div`
  padding: 24px 16px;
  background-color: #f1f1f4;

  p {
    margin-top: 0;
  }
`

const StyledInput = styled.input.attrs({
  type: 'text'
})`
  box-sizing: border-box;
  border: 0;
  padding: 12px;
  font-size: 1rem;
  width: 100%;
`
