import React, { useCallback } from 'react'
import _ from 'lodash'
import { useTranslation } from 'react-i18next'

import { makeStyles } from '@material-ui/core/styles'

import * as Helper from '../util/Helper.js'
import TableStructure from './TableStructure'
import { infoTableHeaders } from '../constant.js'
import TotalLikes from '../likes/TotalLikes.js'
import TotalComments from '../single-proposal/Comments/TotalComments.js'

const useStyles = makeStyles({
  class: {
    width: '7.5rem',
    fontWeight: 700,
    fontSize: '0.75rem'
  },
  classBox: {
    padding: '6px 4px 4px',
    textAlign: 'center',
    color: '#fff'
  },
  classHolder: {
    paddingTop: 5
  },
  juryRate: {
    padding: '4px 10px 2px',
    backgroundColor: '#e8e8e8',
    color: '#000',
    fontWeight: 'bold',
    fontSize: '0.875rem',
    lineHeight: '1.5',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  }
})

function checkNumberIsFloat(value) {
  return Number(value) === value && value % 1 !== 0
}

function getCompetitionEntryValue(proposal, categoryName) {
  const data =
    !_.isEmpty(proposal.excelData) &&
    proposal.excelData.competitionEntry.EN.length > 0
      ? proposal.excelData.competitionEntry.EN.find(
          element => element.name === categoryName
        )
      : {}
  const value = !_.isEmpty(data) ? data.value : null
  const formattedValue = checkNumberIsFloat(value) ? value.toFixed(2) : value

  return formattedValue
}

function spaceBetweenThousands(value) {
  return value ? value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ') : ''
}

function generateInfoTableColumns(t) {
  return infoTableHeaders.map(({ header, accessor }) => {
    return {
      Header: t(header),
      accessor,
      Cell: ({ cell: { value } }) => spaceBetweenThousands(value)
    }
  })
}

function generateStatisticsValueObj(value) {
  const statisticsValueObj = {}

  infoTableHeaders.forEach(({ accessor, header }) => {
    statisticsValueObj[accessor] = getCompetitionEntryValue(value, header)
  })

  return statisticsValueObj
}

export default function InfoTable({
  Data,
  proposalsOrdered,
  PublicClasses,
  users,
  ratingCriteria
}) {
  const classes = useStyles()
  const { t } = useTranslation('proposals')

  const classValueHtml = useCallback(
    value => {
      return (
        <div className={classes.class}>
          {value && (
            <div
              className={classes.classBox}
              style={{ backgroundColor: value.colour }}
            >
              {value.class}
            </div>
          )}
        </div>
      )
    },
    [classes.class, classes.classBox]
  )

  const renderJuryRate = useCallback(
    value => {
      return value ? (
        <div className={classes.juryRate}>{value.toFixed(2)}</div>
      ) : null
    },
    [classes.juryRate]
  )

  const renderLikesComments = useCallback(proposalId => {
    return proposalId ? (
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          gap: 5,
          marginTop: 10
        }}
      >
        <TotalLikes proposalId={proposalId} style={{ minWidth: 40 }} />
        <TotalComments proposalId={proposalId} style={{ minWidth: 40 }} />
      </div>
    ) : null
  }, [])

  const columns = React.useMemo(
    () => [
      {
        Header: 'Proposals', // Unique key and cannot remove
        columns: [
          {
            Header: t('No.'),
            accessor: 'no'
          },
          {
            Header: t('Pseudonym'),
            accessor: 'pseudonym'
          },
          {
            Header: t('Class'),
            accessor: 'proposalClass',
            disableSorting: true,
            Cell: ({ cell: { value } }) => classValueHtml(value)
          },
          {
            Header: 'R',
            accessor: 'juryRate',
            disableSorting: true,
            Cell: ({ cell: { value } }) => renderJuryRate(value)
          },
          {
            Header: 'Likes & Comments',
            accessor: 'likesAndComments',
            disableSorting: true,
            Cell: ({ cell }) =>
              renderLikesComments(cell?.row?.original?.proposalId)
          }
        ]
      },
      {
        Header: 'Info', // Unique key and cannot remove
        columns: generateInfoTableColumns(t)
      }
    ],
    [t, classValueHtml, renderJuryRate, renderLikesComments]
  )

  const calculateProposalAverageScore = React.useCallback(
    (ratesWithMultiplier, ratingCriteria) => {
      if (!ratingCriteria) return

      const totalMultiplier = ratingCriteria.reduce((acc, item) => {
        const rateValue = ratesWithMultiplier.rate[item.name]
        return rateValue ? acc + item.multiplier : acc
      }, 0)

      const score = ratingCriteria.reduce((acc, item) => {
        const rateValue = ratesWithMultiplier.rate[item.name]
        const multiplier = item.multiplier
        return rateValue ? acc + rateValue * multiplier : acc
      }, 0)

      const averageScore = totalMultiplier ? score / totalMultiplier : 0

      return averageScore
    },
    []
  )

  const generateArrayOfSingleRowObj = useCallback(() => {
    let singleRowObj = {}
    let statisticsValueObj = {}

    return _.map(Data, (value, key) => {
      const { number, name } = value
      const proposalClass = Helper.getProposalClass(
        key,
        proposalsOrdered,
        PublicClasses
      )
      // const proposalAllRate = Helper.getProposalAllRate(users, key)
      const ratesWithMultiplier = Helper.getProposalAllRateWithMultiplier(
        users,
        key,
        ratingCriteria
      )
      const averageScore = calculateProposalAverageScore(
        ratesWithMultiplier,
        ratingCriteria
      )

      let proposalNoNameObj = {
        no: number,
        pseudonym: name,
        proposalId: key
      }

      // Add class into 'Class' column
      if (proposalClass) {
        proposalNoNameObj = {
          ...proposalNoNameObj,
          proposalClass: proposalClass
        }
      }

      // Add rate into 'R' column
      if (!_.isEmpty(ratesWithMultiplier.rate)) {
        proposalNoNameObj = {
          ...proposalNoNameObj,
          juryRate: averageScore
        }
      }

      statisticsValueObj = generateStatisticsValueObj(value)

      // Combine proposal's no, name and statistic value object
      singleRowObj = {
        ...proposalNoNameObj,
        ...statisticsValueObj
      }
      // console.log('singleRowObj:', singleRowObj)

      return singleRowObj
    })
  }, [
    Data,
    PublicClasses,
    calculateProposalAverageScore,
    proposalsOrdered,
    ratingCriteria,
    users
  ])

  // Only re-render when data in table is changed
  const data = React.useMemo(() => generateArrayOfSingleRowObj(), [
    generateArrayOfSingleRowObj
  ])

  return (
    <TableStructure columns={columns} data={data} disableMultiSort={true} />
  )
}
