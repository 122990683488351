import React from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'

import useFirestore from './hooks/useFirestore'
import useCollection from './hooks/useCollection'

const initialThemeColors = [
  {
    id: 'completeStatusColor',
    label: 'Complete status color',
    value: '#009246'
  },
  {
    id: 'inCompleteStatusColor',
    label: 'Incomplete status color',
    value: '#BD2719'
  },
  {
    id: 'mainBackgroundColor',
    label: 'Main background color',
    value: '#FFFFFF'
  },
  {
    id: 'mainBrandColor',
    label: 'Main brand color',
    value: '#9fc9eb'
  },
  {
    id: 'mainTextColor',
    label: 'Main text color',
    value: '#000000'
  },
  {
    id: 'secondaryBrandColor',
    label: 'Secondary brand color',
    value: '#9FC9EB'
  },
  {
    id: 'secondaryTextColor',
    label: 'Secondary text color',
    value: '#DEDFE1'
  },
  {
    id: 'timeBannerBgColor',
    label: 'Time banner background color',
    value: '#9fc9eb'
  },
  {
    id: 'timeBannerTextColor',
    label: 'Time banner text color',
    value: '#FFFFFF'
  },
  {
    id: 'buttonPassiveBgColor',
    label: 'Button passive background color',
    value: '#9fc9eb'
  }
]

export default function ManageThemeColors() {
  const { updateDocument } = useFirestore('info')
  const { documents } = useCollection('info')
  const [values, setValues] = React.useState(initialThemeColors)
  const { t } = useTranslation('proposals')

  const onChangeHandler = (e, themeId) => {
    setValues(prevValues => {
      // Find the index of the theme object with the matching id
      const index = prevValues.findIndex(theme => theme.id === themeId)

      // Create a new array with the updated theme object
      const newValues = [...prevValues]
      newValues[index] = { ...newValues[index], value: e.target.value }

      return newValues
    })
  }

  const onBlurHandler = themeId => {
    const theme = values.find(theme => theme.id === themeId)

    updateDocument('theme-colors', {
      [themeId]: theme
    })
  }

  React.useEffect(() => {
    if (!!documents && !!documents['theme-colors']) {
      setValues(Object.values(documents['theme-colors']))
    }
  }, [documents])

  if (!documents) {
    return <div />
  }

  return (
    <div>
      <StyledNote className="note">
        ***
        {t(
          'Click outside the color input after changing the color to see the changes take effect.'
        )}
      </StyledNote>
      {values.map(theme => (
        <Wrapper key={theme.id}>
          <StyledLabel>{t(theme.label)}:</StyledLabel>
          <InputsWrapper>
            <StyledColorInput type="color" value={theme.value} readOnly />
            <StyledTextInput
              type="text"
              value={values[theme.id] || theme.value}
              onChange={e => onChangeHandler(e, theme.id)}
              onBlur={() => onBlurHandler(theme.id)}
            />
          </InputsWrapper>
        </Wrapper>
      ))}
    </div>
  )
}

const StyledNote = styled.p`
  font-size: 14px;
  color: #777;
  margin-bottom: 1rem;
`

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
  margin-bottom: 1rem;
`

const StyledLabel = styled.label`
  min-width: 240px;
`

const InputsWrapper = styled.div`
  display: flex;
  align-items: center;
`

const StyledColorInput = styled.input`
  border: none;
  width: 40px;
  height: 40px;
  padding: 0;
  background-color: transparent;
  pointer-events: none;
`

const StyledTextInput = styled.input`
  padding: 5px;
  width: 100px;
  text-align: right;
  text-transform: uppercase;
  font-size: 16px;
`
