import React from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { Tabs, TabList, Tab, TabPanels, TabPanel } from '@reach/tabs'
import '@reach/tabs/styles.css'

import Info from './Info'
import SubmissionItems from './SubmissionItems'
import ManageThemeColors from './ManageThemeColors'

export default function SubmitSetup() {
  const { t } = useTranslation('proposals')

  return (
    <Tabs>
      <StyledTabList>
        <Tab>{t('General Information')}</Tab>
        <Tab>{t('Submission Fields')}</Tab>
        <Tab>{t('Theme colors')}</Tab>
      </StyledTabList>

      <TabPanels>
        <TabPanel>
          <Info />
        </TabPanel>
        <TabPanel>
          <SubmissionItems />
        </TabPanel>
        <TabPanel>
          <ManageThemeColors />
        </TabPanel>
      </TabPanels>
    </Tabs>
  )
}

const StyledTabList = styled(TabList)`
  border-bottom: 1px solid #f1f1f4;
  margin-bottom: 2rem;
  background-color: #fff;

  [data-reach-tab] {
    padding: 10px 16px;
    transition: color 0.2s ease;

    &:hover {
      color: hsl(231, 48%, 48%);
    }
  }

  [data-selected] {
    border-bottom: 3px solid hsl(231, 48%, 48%);
    color: hsl(231, 48%, 48%);
  }
`
