import React from 'react'
import * as FileSaver from 'file-saver'
import * as XLSX from 'xlsx'
import { useTranslation } from 'react-i18next'

export default function ExportToExcel({
  excelData,
  fileName,
  wscols,
  submissionDeadline
}) {
  const { t } = useTranslation('proposals')
  const fileType =
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8'
  const fileExtension = '.xlsx'
  const sheetName = !!submissionDeadline
    ? submissionDeadline
        .toDate()
        .toLocaleDateString('fi-FI', {
          year: 'numeric',
          month: 'long',
          day: 'numeric',
          hour: 'numeric'
        })
        .toUpperCase()
    : 'data'

  const exportToCSV = (apiData, fileName) => {
    const ws = XLSX.utils.json_to_sheet(apiData)
    ws['!cols'] = wscols
    const wb = {
      Sheets: { [sheetName]: ws },
      SheetNames: [`${sheetName}`]
    }
    const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' })
    const data = new Blob([excelBuffer], { type: fileType })
    FileSaver.saveAs(data, fileName + fileExtension)
  }

  return (
    <div onClick={e => exportToCSV(excelData, fileName)}>
      {t('Table as .xlxs')}
    </div>
  )
}
