import React from 'react'
import JSZip from 'jszip'
import JSZipUtils from 'jszip-utils'
import { saveAs } from 'file-saver'
import { useTranslation } from 'react-i18next'

const zip = new JSZip()

export default function DownloadMaterials({ urls, fileName, setLoading }) {
  const { t } = useTranslation('proposals')

  const handleDownload = () => {
    let count = 0
    const zipFilename = `${fileName} Proposals Data.zip`
    setLoading(true)

    urls.forEach(obj => {
      // loading a file and add it in a zip file
      JSZipUtils.getBinaryContent(obj.downloadUrl, function(err, data) {
        if (err) {
          throw err // or handle the error
        }

        // const proposalName = `${proposalDataFromJSON.find(
        //   proposal => proposal.proposalId === obj.proposalId
        // ).name || obj.proposalId}`
        const fileName = `${obj.pseudonym}/${obj.fileName}`
        zip.file(fileName, data, { binary: true, createFolders: true })

        count++

        if (count === urls.length) {
          zip.generateAsync({ type: 'blob' }).then(function(content) {
            saveAs(content, zipFilename)
            setLoading(false)
          })
        }
      })
    })
  }

  return <div onClick={handleDownload}>{t('Proposals Data as .zip')}</div>
}
