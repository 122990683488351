import React from 'react'
import { LineChart, Line, BarChart, Bar, YAxis } from 'recharts'
import { useTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { firestoreConnect } from 'react-redux-firebase'

import SettingsIcon from '@material-ui/icons/Settings'
import RemoveRedEyeIcon from '@material-ui/icons/RemoveRedEye'
import { withStyles } from '@material-ui/core/styles'
import Tooltip from '@material-ui/core/Tooltip'
import Zoom from '@material-ui/core/Zoom'
import InfoIcon from '@material-ui/icons/Info'

import Button from '../inputs/Button'
// import { rating_fields } from './rating_fields'
import RatingIcons from '../reusable/RatingIcons'

const styles = theme => ({
  chartContainer: {
    width: '170px',
    display: 'flex',
    justifyContent: 'space-between',
    position: 'relative',
    overflowX: 'scroll'
  },
  line: {
    height: '144px',
    width: '1px',
    background: '#e0e2e4'
  },
  toolTip: {
    fontSize: '12px'
  },
  overall_rate: {
    position: 'absolute',
    top: -15,
    left: -5,
    color: '#000',
    fontWeight: 'bold',
    fontSize: 13
  },
  averageScore: {
    display: 'inline-block',
    // width: '25px',
    // height: '25px',
    marginLeft: '15px',
    padding: '4px 10px 2px',
    backgroundColor: '#e8e8e8',
    color: '#000',
    fontWeight: 'bold',
    textAlign: 'center',
    lineHeight: '27px',
    fontSize: 18
  },
  infoToolTip: {
    padding: '16px',
    fontSize: '14px'
  }
})

const CustomizeDots = ({ cx, cy, stroke, payload, value, secretary }) => {
  if (payload.show_dot) {
    return (
      <circle cx={cx} cy={cy} r={5} fill={secretary ? '#353c43' : '#F71E06'} />
    )
  }
  return false
}

const CustomizedLabel = props => {
  const { x, y, stroke, value, index } = props
  const xOffset = 5 // Change this value to adjust the x position of the label
  const yOffset = 20 // Change this value to adjust the y position of the label

  return (
    <text
      x={index === 0 ? x + xOffset : index === 3 ? x - xOffset : x}
      y={y + yOffset}
      dy={-4}
      fill={stroke}
      fontSize={13}
      fontWeight={700}
      textAnchor="middle"
    >
      {value?.toFixed(1)}
    </text>
  )
}

const Review = props => {
  const {
    title,
    edit,
    rate,
    classes,
    allRates,
    secretary,
    openRatingModal,
    openAllRatingsModal,
    proposalId,
    ratesWithMultiplier,
    ratingCriteria
  } = props

  const { t } = useTranslation('proposals')

  const getLines = () => {
    const lines = ratingCriteria.filter(item => item.name !== 'overall')

    return lines.map(item => (
      <div key={item.name} className="d-flex flex-column align-items-center">
        <div className={classes.line}></div>
        <Tooltip
          title={t(item.label)}
          aria-label={item.label}
          TransitionComponent={Zoom}
          placement="left-end"
          classes={{ tooltip: classes.toolTip }}
        >
          <RatingIcons iconName={item.iconName} />
        </Tooltip>
      </div>
    ))
  }

  const getLineChartValues = rate => {
    if (rate) {
      const linesChart = ratingCriteria.filter(item => item.name !== 'overall')

      // return linesChart.map(item => {
      //   const calculatedRate = ((5 * rate[item.name] - 2.49) / 4.51).toFixed(2)
      //   const currentRate = rate[item.name] ? calculatedRate : 0
      //   return { ...item, rate: currentRate }
      // })

      return linesChart.map(item => {
        const currentRate = rate[item.name] ? rate[item.name] : 0
        return { ...item, rate: currentRate }
      })
    }
  }

  const getOverallValue = rate => {
    if (rate) {
      const overall =
        rate.overall && rate.overall <= 5
          ? (rate.overall / 5) * 100
          : rate.overall && rate.overall > 5
          ? 100
          : 0
      const other = 100 - overall
      return [{ overall, other }]
    }
  }

  const chartLinearGradientColor = rate => {
    const colors = {
      0: '#F71E06',
      1: '#F71E06',
      2: '#F78606',
      3: '#F2E713',
      4: '#87A91F',
      5: '#2A8D08'
    }

    return colors[rate] || '#3f51b5'
  }

  const chartLinearGradientStopsColor = (min, max) => {
    let stops = { color0: '', color50: '', color100: '' }

    stops.color0 = chartLinearGradientColor(min)
    stops.color100 = chartLinearGradientColor(max)

    if (max - min > 1) {
      stops.color50 = chartLinearGradientColor(Math.floor((min + max) / 2))
    }

    return stops
  }

  const lineChartColors = lineChartValues => {
    if (!lineChartValues) return

    const max = Math.max(
      ...lineChartValues.map(val => (val.rate ? Math.round(val.rate) : 2))
    )
    const min = Math.min(
      ...lineChartValues.map(val => (val.rate ? Math.round(val.rate) : 0))
    )
    const stops = chartLinearGradientStopsColor(min, max)

    return stops
  }

  const barChartColors = barChartValues => {
    const min = 1
    const max = barChartValues ? Math.round(barChartValues[0].overall / 20) : 2
    const stops = chartLinearGradientStopsColor(min, max)

    return stops
  }

  // Line chart doesn't connect null
  const removeRateProperty = arr => {
    arr.forEach((obj, i) => {
      const firstWithDot = i === 0 && obj.rate !== 0 && arr[1] === 0
      const lastWithDot =
        i === arr.length - 1 && obj.rate !== 0 && arr[i - 1].rate === 0
      const middleWithDot =
        i > 0 &&
        i < arr.length - 1 &&
        obj.rate !== 0 &&
        arr[i - 1].rate === 0 &&
        arr[i + 1].rate === 0

      if (firstWithDot || lastWithDot || middleWithDot) {
        obj.show_dot = true
      }
    })

    return arr.map(obj => {
      if (obj.rate === 0) {
        delete obj.rate
      }
      return obj
    })
  }

  const calculateProposalAverageScore = () => {
    if (!ratingCriteria) return

    const totalMultiplier = ratingCriteria.reduce((acc, item) => {
      const rateValue = rate[item.name]
      return rateValue ? acc + Number(item.multiplier) : acc
    }, 0)

    const score = ratingCriteria.reduce((acc, item) => {
      const rateValue = rate[item.name]
      const multiplier = item.multiplier
      return rateValue ? acc + rateValue * multiplier : acc
    }, 0)

    const averageScore = totalMultiplier ? score / totalMultiplier : 0

    return averageScore
  }

  const infoIconContent = (
    <>
      <p>
        {t(
          'The weighted criteria averages are calculated by taking the average of the ratings provided by the reviewers, each adjusted by their individual rating factors. If a reviewer does not provide a rating for a specific criterion, their input for that criterion average is excluded due to a ‘null’ status, ensuring missing ratings do not skew the average calculation. Similarly, for the weighted proposal rating, any criterion will be applied a rating criteria multiplier and ones with a missing average rating are also excluded from the calculation to maintain accuracy.'
        )}
      </p>
      <p>{`${t('Rating criteria multipliers')}:`}</p>
      <ul>
        {ratingCriteria &&
          ratingCriteria.map(item => {
            const label = t(item.label)
            return (
              <li key={item.name}>
                {`${label}: ${item.multiplier}`}
                <br />
              </li>
            )
          })}
      </ul>
    </>
  )

  const lines = getLines()
  const lineChartValues = getLineChartValues(rate)
  const formattedLineChartValues = lineChartValues
    ? removeRateProperty(lineChartValues)
    : []
  const lineChartValuesStopColor = lineChartColors(formattedLineChartValues)

  const lineChartValuesAll = allRates ? getLineChartValues(allRates.rate) : null
  const formattedLineChartValuesAll = lineChartValuesAll
    ? removeRateProperty(lineChartValuesAll)
    : []
  const overallValue = getOverallValue(rate)
  // const overallValueAll = allRates ? getOverallValue(allRates.rate) : null
  const overallValueAllWithMultiplier = ratesWithMultiplier
    ? getOverallValue(ratesWithMultiplier.rate)
    : null
  const barChartStopColor = barChartColors(overallValueAllWithMultiplier)
  const proposalAverageScore = calculateProposalAverageScore()

  return (
    <div>
      <div className="d-flex align-items-center justify-content-between">
        {title && <h5 className="mb-0">{title} </h5>}

        <div className="d-flex flex-column align-items-end">
          {edit && (
            <Button
              style={{ padding: '5px' }}
              onClick={openRatingModal}
              size="sm"
            >
              <span className="d-flex align-items-center">
                <span className="mr-1">{t('Manage')} </span>
                <SettingsIcon fontSize="small" />
              </span>
            </Button>
          )}
          {/* {secretary && !!allRates.userVoted && (
            <Button
              style={{ padding: 0 }}
              onClick={openAllRatingsModal}
              size="sm"
            >
              <span className="d-flex align-items-center">
                <span className="mr-1">{t('All ratings')} </span>
                <RemoveRedEyeIcon fontSize="small" />
              </span>
            </Button>
          )} */}
        </div>
      </div>

      {secretary && allRates && (
        <div className="xs">{`(${allRates.userVoted}/${allRates.users} ${t(
          'reviewers'
        )})`}</div>
      )}

      {!!proposalAverageScore && (
        <div className="d-flex align-items-center mb-4">
          <div className="d-flex align-items-center" style={{ gap: 5 }}>
            <h4 style={{ margin: 0, fontSize: 14 }}>{t('Proposal Rating')}</h4>
            <Tooltip
              title={infoIconContent}
              aria-label={infoIconContent}
              placement="top-start"
              TransitionComponent={Zoom}
              classes={{ tooltip: classes.infoToolTip }}
            >
              <InfoIcon />
            </Tooltip>
          </div>
          <div className={classes.averageScore}>
            {proposalAverageScore ? proposalAverageScore.toFixed(2) : 0}
          </div>
        </div>
      )}

      {lineChartValues.length > 0 && (
        <div>
          <div className="d-flex mt-2 position-relative">
            <div className="pl-1 pr-2 d-flex flex-column align-items-center">
              <div className="d-flex">
                {secretary && allRates && (
                  <BarChart
                    margin={{ top: 0, right: 0, left: 0, bottom: 0 }}
                    width={5}
                    height={144}
                    data={overallValueAllWithMultiplier}
                  >
                    <Bar dataKey="overall" stackId="a" fill="#353c43" />
                    <Bar dataKey="other" stackId="a" fill="#ececec" />
                  </BarChart>
                )}
                <div style={{ position: 'relative' }}>
                  {!!rate?.overall ? (
                    <div className={classes.overall_rate}>
                      {rate.overall.toFixed(1) > 5
                        ? 5
                        : rate.overall.toFixed(1)}
                    </div>
                  ) : null}
                  <BarChart
                    margin={{ top: 0, right: 0, left: 0, bottom: 0 }}
                    width={secretary && allRates ? 5 : 10}
                    height={144}
                    data={overallValue}
                  >
                    <defs>
                      <linearGradient
                        id={`barColor-${proposalId}`}
                        x1="0%"
                        y1="100%"
                        x2="0%"
                        y2="0%"
                      >
                        <stop
                          offset="0%"
                          style={{
                            stopColor: barChartStopColor.color0,
                            stopOpacity: 1
                          }}
                        />
                        {barChartStopColor.color50 && (
                          <stop
                            offset="50%"
                            style={{
                              stopColor: barChartStopColor.color50,
                              stopOpacity: 1
                            }}
                          />
                        )}
                        <stop
                          offset="100%"
                          style={{
                            stopColor: barChartStopColor.color100,
                            stopOpacity: 1
                          }}
                        />
                      </linearGradient>
                    </defs>
                    <Bar
                      dataKey="overall"
                      stackId="a"
                      fill={`url(#barColor-${proposalId})`}
                    />
                    <Bar dataKey="other" stackId="a" fill="#ececec" />
                  </BarChart>
                </div>
              </div>

              <Tooltip
                title={t(ratingCriteria[0].label)}
                aria-label={ratingCriteria[0].label}
                TransitionComponent={Zoom}
                placement="left-end"
                classes={{ tooltip: classes.toolTip }}
              >
                <RatingIcons iconName={ratingCriteria[0].iconName} />
              </Tooltip>
            </div>

            <div className={classes.chartContainer}>
              {lines}
              {secretary && allRates && (
                <div className="position-absolute">
                  <LineChart
                    width={170}
                    height={144}
                    data={formattedLineChartValuesAll}
                  >
                    <Line
                      dot={<CustomizeDots secretary={secretary} />}
                      type="monotone"
                      dataKey="rate"
                      stroke="#353c43"
                      strokeWidth="4"
                      isAnimationActive={false}
                    />
                    <YAxis type="number" domain={[0, 5]} hide={true} />
                  </LineChart>
                </div>
              )}
              <div className="position-absolute">
                <LineChart
                  width={170}
                  height={144}
                  data={formattedLineChartValues}
                >
                  <defs>
                    <linearGradient
                      id={`lineColor-${proposalId}`}
                      x1="0%"
                      y1="100%"
                      x2="0%"
                      y2="0%"
                    >
                      <stop
                        offset="0%"
                        style={{
                          stopColor: lineChartValuesStopColor.color0,
                          stopOpacity: 1
                        }}
                      />
                      {lineChartValuesStopColor.color50 && (
                        <stop
                          offset="50%"
                          style={{
                            stopColor: lineChartValuesStopColor.color50,
                            stopOpacity: 1
                          }}
                        />
                      )}
                      <stop
                        offset="100%"
                        style={{
                          stopColor: lineChartValuesStopColor.color100,
                          stopOpacity: 1
                        }}
                      />
                    </linearGradient>
                  </defs>
                  <Line
                    dot={{
                      fill: '#353c43',
                      strokeWidth: 1,
                      r: 3,
                      strokeDasharray: ''
                    }}
                    type="monotone"
                    dataKey="rate"
                    label={<CustomizedLabel />}
                    stroke={`url(#lineColor-${proposalId})`}
                    strokeWidth="4"
                    isAnimationActive={false}
                  />
                  <YAxis type="number" domain={[0, 5]} hide={true} />
                </LineChart>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  )
}

const mapStateToProps = state => {
  return {
    ratingCriteria: state.firestore.ordered.RatingCriteria
  }
}

export default compose(
  connect(mapStateToProps, null),
  firestoreConnect([{ collection: 'RatingCriteria' }])
)(withStyles(styles)(Review))
