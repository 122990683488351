import React from 'react'
import { useTranslation } from 'react-i18next'

import IconButton from '@material-ui/core/IconButton'
import EditIcon from '@material-ui/icons/Edit'
import Grid from '@material-ui/core/Grid'
import { Typography } from '@material-ui/core'

import ManageUser from './ManageUser'
import DeleteAlert from './DeleteAlert'

export default function UsersDetails({ user }) {
  // const [isModalOpen, setIsModalOpen] = React.useState(false)
  const { t } = useTranslation('proposals')

  // const openModal = () => setIsModalOpen(true)
  // const closeModal = () => setIsModalOpen(false)

  return (
    <Grid container alignItems="center" className="border-bottom py-1">
      <Grid item xs={3} className="h5 mb-0">
        {user.firstName} {user.lastName}
      </Grid>
      <Grid item xs={3} className="sm">
        {user.email}
      </Grid>
      <Grid item xs={2} className="sm">
        {user.proffesion}
      </Grid>
      <Grid item xs={1} className="sm">
        {user.role === 'judge' ? t('judge') : t('secretary')}
      </Grid>
      <Grid item xs={2} className="sm">
        <Typography align="center">
          {user?.rateFactor ? parseFloat(user.rateFactor).toFixed(2) : 0}
        </Typography>
      </Grid>
      <Grid item xs={1} className="sm">
        <div className="d-flex align-items-center justify-content-end">
          {/* <IconButton
            onClick={openModal}
            className="mr-1"
            size="small"
            aria-label="Delete"
          >
            <EditIcon fontSize="medium" />
          </IconButton> */}
          <ManageUser userData={user}>
            <IconButton className="mr-1" size="small" aria-label="Delete">
              <EditIcon fontSize="medium" />
            </IconButton>
          </ManageUser>
          <DeleteAlert user={user} />
        </div>
      </Grid>
      {/* {isModalOpen && (
        <ManageUser close={closeModal} isOpen={isModalOpen} userData={user} />
      )} */}
    </Grid>
  )
}

// class UsersDetails extends Component {
//   state = {
//     isModalOpen: false
//   }

//   openModal = () => {
//     this.setState({ isModalOpen: true })
//   }
//   closeModal = () => {
//     this.setState({ isModalOpen: false })
//   }

//   render() {
//     const { user, t } = this.props
//     return (
//       <Grid container alignItems="center" className="border-bottom py-1">
//         <Grid item xs={3} className="h5 mb-0">
//           {user.firstName} {user.lastName}
//         </Grid>
//         <Grid item xs={3} className="sm">
//           {user.email}
//         </Grid>
//         <Grid item xs={2} className="sm">
//           {user.proffesion}
//         </Grid>
//         <Grid item xs={2} className="sm">
//           {user.role === 'judge' ? t('judge') : t('secretary')}
//         </Grid>
//         <Grid item xs={1} className="sm">
//           <Typography align="left">
//             {user?.rateFactor ? user.rateFactor.toFixed(2) : 0}
//           </Typography>
//         </Grid>
//         <Grid item xs={1} className="sm">
//           <div className="d-flex align-items-center">
//             <IconButton
//               onClick={this.openModal}
//               className="mr-1"
//               size="small"
//               aria-label="Delete"
//             >
//               <EditIcon fontSize="medium" />
//             </IconButton>
//             <DeleteAlert user={user} />
//           </div>
//         </Grid>
//         {this.state.isModalOpen && (
//           <ManageUser
//             close={this.closeModal}
//             isOpen={this.state.isModalOpen}
//             userData={user}
//           />
//         )}
//       </Grid>
//     )
//   }
// }

// export default withTranslation('proposals')(UsersDetails)
