import React from 'react'
import styled from 'styled-components'
import { AiFillLike } from 'react-icons/ai'

import useCollection from '../competition-info/submission/hooks/useCollection'

export default function TotalLikes({ proposalId, style }) {
  const [likes, setLikes] = React.useState(0)
  const { documents } = useCollection('likes')

  React.useEffect(() => {
    if (documents) {
      if (!proposalId) return setLikes(Object.values(documents).length)
      const likes = Object.values(documents).filter(
        doc => doc.proposalId === proposalId
      ).length
      return setLikes(likes)
    }
    return setLikes(0)
  }, [documents, proposalId])

  if (!likes) return null

  return (
    <Wrapper style={style}>
      <LikeIcon />
      <StyledSpan>{likes}</StyledSpan>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: -9px;
  padding: 3px 5px;
  background-color: #ee7d84;
  color: #fbdfdf;
  font-size: 16px;
  font-weight: 700;
  position: relative;
`

const StyledSpan = styled.span`
  margin-bottom: -2px;
  font-weight: 700;
  font-size: 14px;
`

const LikeIcon = styled(AiFillLike)`
  width: 13px;
  height: 13px;
  margin-right: 4px;
`
