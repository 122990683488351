import React from 'react'

import { projectFirestore } from '../../../util/fbconfig'

let initialState = {
  document: null,
  isPending: false,
  error: null,
  success: null
}

const firestoreReducer = (state, action) => {
  switch (action.type) {
    case 'IS_PENDING':
      return { document: null, isPending: true, error: null, success: null }
    case 'ADDED_DOCUMENT':
      return {
        document: action.payload,
        isPending: false,
        error: null,
        success: true
      }
    case 'UPDATED_DOCUMENT':
      return {
        document: action.payload,
        isPending: false,
        error: null,
        success: true
      }
    case 'DELETED_DOCUMENT':
      return {
        isPending: false,
        error: null,
        success: true
      }
    case 'ERROR':
      return {
        document: null,
        isPending: false,
        error: action.payload,
        success: false
      }
    default:
      return state
  }
}

export default function useFirestore(collection) {
  const [response, dispatch] = React.useReducer(firestoreReducer, initialState)
  const [isCancelled, setIsCancelled] = React.useState(false)

  // Collection ref
  const ref = projectFirestore.collection(collection)

  const dispatchIfNotCancelled = action => {
    if (!isCancelled) {
      dispatch(action)
    }
  }

  const addDocumentWithFirebaseId = async data => {
    console.log('data:', data)
    dispatch({ type: 'IS_PENDING' })

    const doc = await ref.doc(data.id).get()

    if (doc.exists) {
      console.log('Update exits document')
      ref
        .doc(doc.id)
        .set(data, { merge: true })
        .then(() => {
          dispatchIfNotCancelled({
            type: 'UPDATED_DOCUMENT',
            payload: data
          })
        })
    } else {
      console.log('Add new document')
      ref
        .add(data)
        .then(docRef => {
          const newData = { ...data, id: docRef.id }
          // Update the document with the new id
          ref.doc(docRef.id).set(newData, { merge: true })

          dispatchIfNotCancelled({
            type: 'ADDED_DOCUMENT',
            payload: newData
          })
        })
        .catch(error => {
          console.error('Error adding document:', error)
          dispatchIfNotCancelled({ type: 'ERROR', payload: error.message })
        })
    }
  }

  // Add a document
  const addDocument = async (id, doc) => {
    dispatch({ type: 'IS_PENDING' })

    try {
      // const createdAt = timestamp.fromDate(new Date())
      const addedDocument = await ref.doc(id).set({ ...doc })
      dispatchIfNotCancelled({
        type: 'ADDED_DOCUMENT',
        payload: addedDocument
      })
    } catch (error) {
      dispatchIfNotCancelled({ type: 'ERROR', payload: error.message })
    }
  }

  // Update a document
  const updateDocument = async (id, updates) => {
    dispatch({ type: 'IS_PENDING' })

    try {
      const updatedDocument = await ref.doc(id).set(updates, { merge: true })
      dispatchIfNotCancelled({
        type: 'UPDATED_DOCUMENT',
        payload: updatedDocument
      })

      return updatedDocument
    } catch (error) {
      dispatchIfNotCancelled({ type: 'ERROR', payload: error.message })
    }
  }

  // Delete a document
  const deleteDocument = async id => {
    dispatch({ type: 'IS_PENDING' })

    try {
      await ref.doc(id).delete()
      dispatchIfNotCancelled({ type: 'DELETED_DOCUMENT' })
    } catch (error) {
      dispatchIfNotCancelled({ type: 'ERROR', payload: error.message })
    }
  }

  React.useEffect(() => {
    // Unsubscribe on unmount
    return () => setIsCancelled(true)
  })

  return {
    addDocument,
    addDocumentWithFirebaseId,
    updateDocument,
    deleteDocument,
    response
  }
}
