import React from 'react'
import { MdInsertChart, MdOutlineArchitecture } from 'react-icons/md'
import { GiModernCity } from 'react-icons/gi'
import { BsFillPeopleFill, BsBarChartSteps } from 'react-icons/bs'

export const rating_fields = [
  {
    name: 'overall',
    label: 'Total',
    svg: <MdInsertChart />
  },
  {
    name: 'kaupunkikuvaan_ja_kaupunkirakenteeseen_sovittaminen',
    label: 'Kaupunkikuvaan ja kaupunkirakenteeseen sovittaminen',
    svg: <GiModernCity />
  },
  {
    name:
      'kokonaistaloudellisuus_ja_edellytykset_elinkaaritavoitteiden_täyttymiseen_sekä_ekologisuus',
    label:
      'Kokonaistaloudellisuus ja edellytykset elinkaaritavoitteiden täyttymiseen sekä ekologisuus',
    svg: <BsBarChartSteps />
  },
  {
    name: 'kulttuurisen_vetovoiman_vahvistaminen',
    label: 'Kulttuurisen vetovoiman vahvistaminen',
    svg: <BsFillPeopleFill />
  },
  {
    name: 'arkkitehtoniset_ja_toiminnalliset_ratkaisut',
    label: 'Arkkitehtoniset ja toiminnalliset ratkaisut',
    svg: <MdOutlineArchitecture />
  }
]
