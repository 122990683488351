import React from 'react'
import { Editor } from 'react-draft-wysiwyg'
import { makeStyles } from '@material-ui/styles'
import PropTypes from 'prop-types'

import useCollection from './submission/hooks/useCollection'

const useStyles = makeStyles(theme => ({
  tabContainer: {
    padding: 32,
    flex: 1,
    backgroundColor: theme.modeColors.navBackground,
    // backgroundColor: 'lightgreen',
    display: 'flex',
    flexDirection: 'column',
    userSelect: 'text'
  },
  tabContainerWithoutPadding: {
    flex: 1,
    backgroundColor: theme.modeColors.navBackground,
    // backgroundColor: 'lightgreen',
    display: 'flex',
    flexDirection: 'column'
  },
  editorWrapper: {
    flex: 1
  }
}))

const SingleInfoTab = ({
  editCompetitionInfo,
  generalInfo,
  editorState,
  changeEditorState,
  createMarkup,
  tabHeader,
  isSecretary
}) => {
  const classes = useStyles()
  const { documents } = useCollection('info')

  if (!documents) return <div />

  return (
    <div className={classes.tabContainer}>
      <h3 className="mb-1">{tabHeader}</h3>

      {editCompetitionInfo && (
        <Editor
          editorState={editorState}
          wrapperClassName={classes.editorWrapper}
          editorClassName="editor-class editor_container"
          toolbar={{
            list: { inDropdown: true },
            textAlign: { inDropdown: true },
            link: { inDropdown: true }
          }}
          onEditorStateChange={changeEditorState}
        />
      )}
      {!editCompetitionInfo && generalInfo ? (
        <div
          style={{
            // flex: 1,
            display: 'flex',
            flexDirection: 'column',
            overflow: 'hidden'
          }}
        >
          <div dangerouslySetInnerHTML={createMarkup} />
        </div>
      ) : null}
    </div>
  )
}

SingleInfoTab.propTypes = {
  // editCompetitionInfo: PropTypes.bool.isRequired,
  // generalInfo: PropTypes.objectOf(PropTypes.string).isRequired,
  // editorState: PropTypes.object.isRequired,
  // changeEditorState: PropTypes.func.isRequired,
  // createMarkup: PropTypes.object.isRequired,
  tabHeader: PropTypes.string.isRequired
}

export default SingleInfoTab
