import React from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import InputBase from '@material-ui/core/InputBase'
import InputLabel from '@material-ui/core/InputLabel'
import FormControl from '@material-ui/core/FormControl'
import FormHelperText from '@material-ui/core/FormHelperText'

const styles = theme => ({
  formControl: {
    width: '100%',
    minHeight: 34
  },
  bootstrapRoot: {
    'label + &': {
      marginTop: theme.spacing(3)
    }
  },
  lg: {
    borderRadius: 0,
    position: 'relative',
    backgroundColor: theme.modeColors.inputBackground,
    border: '1px solid #ccced0',
    fontSize: '1rem',
    width: '100%',
    padding: '8px 12px',
    fontFamily: 'HarmoniaSansStd',
    lineHeight: '1.5'
  },
  md: {
    borderRadius: 0,
    position: 'relative',
    backgroundColor: theme.modeColors.inputBackground,
    border: '1px solid #ccced0',
    fontSize: '0.875rem',
    padding: '7px 12px 5px',
    fontFamily: 'HarmoniaSansStd',
    lineHeight: '1.43'
  },
  activeClass: {
    borderRadius: 0,
    position: 'relative',
    backgroundColor: theme.modeColors.inputBackground,
    border: '2px solid #353c43',
    fontSize: '0.875rem',
    padding: '7px 12px 5px',
    fontFamily: 'HarmoniaSansStd',
    lineHeight: '1.43'
  },
  bootstrapFormLabel: {
    fontSize: '0.875rem',
    fontWeight: 500,
    color: '#353c43',
    fontFamily: 'HarmoniaSansStd',
    lineHeight: '1.43',
    transform: 'unset'
  },
  InputError: {
    '& input': {
      border: '1px solid red !important'
    }
  },
  multiline: {
    '&:focus': {
      border: '1px solid #3f51b5'
    }
  },
  InputFocused: {
    backgroundColor: '#F1f1f1',
    '& input': {
      border: '1px solid #3f51b5'
    }
  }
})

function CustomizedInputs(props) {
  const {
    id,
    classes,
    label,
    size,
    input,
    className,
    validation,
    active,
    ...other
  } = props
  if (!validation) {
    return (
      <FormControl className={classes.formControl}>
        {label && (
          <InputLabel
            shrink
            htmlFor={id}
            classes={{
              root: classes.bootstrapFormLabel
            }}
          >
            {label}
          </InputLabel>
        )}
        <InputBase
          {...other}
          id={id}
          classes={{
            root: classes.bootstrapRoot,
            input: active ? classes.activeClass : classes[size],
            inputMultiline: classes.multiline,
            error: classes.InputError,
            focused: classes.InputFocused
          }}
        />
      </FormControl>
    )
  } else {
    const {
      meta: { touched, error }
    } = props
    return (
      <div className={className}>
        <FormControl className={classes.formControl} error={touched && !!error}>
          {label && (
            <InputLabel
              shrink
              htmlFor={id}
              classes={{
                root: classes.bootstrapFormLabel
              }}
            >
              {label}
            </InputLabel>
          )}
          <InputBase
            {...other}
            {...input}
            id={id}
            classes={{
              root: classes.bootstrapRoot,
              input: classes[size],
              inputMultiline: classes.multiline,
              error: classes.InputError
            }}
          />
          {touched && error && (
            <FormHelperText>{touched && error}</FormHelperText>
          )}
        </FormControl>
      </div>
    )
  }
}

CustomizedInputs.propTypes = {
  classes: PropTypes.object.isRequired
}

export default withStyles(styles)(CustomizedInputs)
