import _ from 'lodash';

const getCommentsDates = allComments => {
  if (!_.isEmpty(allComments)) {
    const allDates = _.map(allComments, comment =>
      comment?.createdAt.toDate().toLocaleDateString()
    );
    const dates = allDates.filter(date => date !== undefined);
    const uniqueDates = [...new Set(dates)];

    return uniqueDates;
  }
};

export const groupCommentsByDate = allComments => {
  const dates = getCommentsDates(allComments);

  if (!!dates.length) {
    const commentsByDate = dates.map(date => ({ date, comments: [] }));

    commentsByDate.forEach(obj => {
      _.forEach(allComments, comment => {
        const commentedAt = comment?.createdAt.toDate().toLocaleDateString();
        if (commentedAt === obj.date) {
          obj.comments.push(comment);
        }
      });
    });

    return commentsByDate;
  }
};

const getCommentsProposalsIds = allComments => {
  if (!_.isEmpty(allComments)) {
    const allProposalsIds = _.map(allComments, comment => comment?.proposalId);
    const proposalsIds = allProposalsIds.filter(proposalId => proposalId !== undefined);
    const uniqueProposalsIds = [...new Set(proposalsIds)];

    return uniqueProposalsIds;
  }
};

const getProposalsInfo = (proposalsIds, proposalsData) => {
  return Object.fromEntries(
    proposalsIds.map(id => [
      id,
      {
        number: proposalsData[id]['number'],
        name: proposalsData[id]['name'],
      },
    ])
  );
};

export const groupCommentsByProposal = (allComments, proposalsData) => {
  const proposalsIds = getCommentsProposalsIds(allComments);
  const proposalsInfo = getProposalsInfo(proposalsIds, proposalsData);

  if (!!proposalsIds.length) {
    const commentsByProposal = proposalsIds.map(proposalId => ({
      proposalId,
      ...proposalsInfo[proposalId],
      comments: [],
    }));

    commentsByProposal.forEach(obj => {
      _.forEach(allComments, comment => {
        if (obj.proposalId === comment?.proposalId) {
          obj.comments.push(comment);
        }
      });
    });

    return commentsByProposal;
  }
};
