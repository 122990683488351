import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { styled } from '@material-ui/styles'

import { sortProposals } from '../redux/actions/proposalsActions'
import Select from '../inputs/Select'
import { createSortBy } from '../util/Helper'
import { addToDataBase } from '../redux/actions/dataBase'
import { useTranslation } from 'react-i18next'

const options2 = [
  {
    label: 'Random',
    value: 1
  },
  {
    label: 'Number - First to last',
    value: 2
  },
  {
    label: 'Number - Last to first',
    value: 3
  }
]

const SortBy = styled('div')(({ theme }) => ({
  color: theme.modeColors.proposalTitle
}))

const Sort = ({
  sortProposals,
  profile,
  enqueueSnackbar,
  addToDataBase,
  userId
}) => {
  const initialSortByValue = profile.sortBy ? profile.sortBy : 1
  const [value, setValue] = useState(initialSortByValue)
  const { t } = useTranslation('proposals')
  const readOnly = process.env.REACT_APP_READ_ONLY

  const optionsForJudge =
    profile.role === 'judge'
      ? [
          {
            label: `${t('Rating - My rating')}`,
            value: 15
          }
        ]
      : []

  const options = [
    {
      label: `${t('Random')}`,
      value: 1
    },
    {
      label: `${t('Number - First to last')}`,
      value: 2
    },
    {
      label: `${t('Number - Last to first')}`,
      value: 3
    },
    {
      label: `${t('Unseen first')}`,
      value: 4
    },
    {
      label: `${t('Seen first')}`,
      value: 5
    },
    {
      label: `${t('Classes')}`,
      value: 6
    },
    {
      label: `${t('Rating - The average score')}`,
      value: 7
    },
    {
      label: `${t('Rating - Jury rating')}`,
      value: 8
    },
    ...optionsForJudge,
    {
      label: `${t('Pseudonym - A to Z')}`,
      value: 9
    },
    {
      label: `${t('Pseudonym - Z to A')}`,
      value: 10
    },
    {
      label: `${t('Likes - most')}`,
      value: 11
    },
    {
      label: `${t('Likes - least')}`,
      value: 12
    },
    {
      label: `${t('Comments - most')}`,
      value: 13
    },
    {
      label: `${t('Comments - least')}`,
      value: 14
    }
  ]

  const handleChange = event => {
    setValue(event.target.value)
    sortProposals(event.target.value)
    // Save to database user choice sort by value
    createSortBy(event.target.value, userId, addToDataBase, enqueueSnackbar)
  }

  return (
    <div id="sort-by" className="d-flex align-items-center">
      <SortBy className="sm pr-1">{`${t('Sort by')}:`}</SortBy>
      <div>
        <Select
          onChange={handleChange}
          value={value}
          options={readOnly ? options2 : options}
          fixedWidth
        />
      </div>
    </div>
  )
}

Sort.propTypes = {
  sortProposals: PropTypes.func.isRequired,
  addToDataBase: PropTypes.func.isRequired,
  profile: PropTypes.object.isRequired,
  userId: PropTypes.string.isRequired
}

const mapStateToProps = state => {
  return {
    profile: state.firebase.profile,
    userId: state.firebase.auth.uid
  }
}

export default connect(mapStateToProps, { sortProposals, addToDataBase })(Sort)
