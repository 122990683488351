import React from 'react'
import { useTranslation } from 'react-i18next'

import TabPanel from '@material-ui/lab/TabPanel'
import TabContext from '@material-ui/lab/TabContext'
import TabList from '@material-ui/lab/TabList'
import Tab from '@material-ui/core/Tab'
import { makeStyles } from '@material-ui/styles'
import useLocalStorage from '../hooks/useLocalStorage'

const RatingTabsForCommonTab = ({
  juryRatingChart,
  averageRatingChart,
  rate
}) => {
  const [savedTabValue, setSavedTabValue] = useLocalStorage(
    'jt-rating-tab',
    '0'
  )
  const [tabValue, setTabValue] = React.useState(savedTabValue)
  const { t } = useTranslation('proposals')
  const classes = useStyles()

  const onChangeTab = (event, newValue) => {
    setTabValue(newValue)
    setSavedTabValue(newValue)
  }

  return (
    <TabContext value={tabValue}>
      <TabList
        indicatorColor="primary"
        textColor="primary"
        classes={{ root: classes.tabs, flexContainer: classes.flexContainer }}
        onChange={onChangeTab}
      >
        <Tab
          classes={{
            root: classes.tab,
            selected: classes.selected,
            wrapper: classes.tabLabel
          }}
          label={t('Average rating')}
          value="0"
        />
        <Tab
          classes={{
            root: classes.tab,
            selected: classes.selected,
            wrapper: classes.tabLabel
          }}
          label={t('Jury rating')}
          value="1"
        />
      </TabList>
      <TabPanel value="0" classes={{ root: classes.tabPanel }}>
        {averageRatingChart}
      </TabPanel>
      <TabPanel value="1" classes={{ root: classes.tabPanel }}>
        {juryRatingChart}
      </TabPanel>
    </TabContext>
  )
}

export default RatingTabsForCommonTab

const useStyles = makeStyles(theme => ({
  tabs: {
    minHeight: 30,
    borderBottom: '1px solid #e0e2e4',
    marginBottom: 16
  },
  tab: {
    fontSize: 13,
    fontFamily: 'HarmoniaSansStd !important',
    padding: 0,
    textAlign: 'left',
    minWidth: 0,
    minHeight: 24,
    marginBottom: 0,
    marginRight: 0,
    fontWeight: 700
  },
  tabLabel: {
    padding: '0.25rem 1rem',
    textTransform: 'capitalize',
    color: theme.modeColors.textColor
  },
  selected: {
    color: `${theme.modeColors.selectedColor} !important`
  },
  tabPanel: {
    padding: 0
  },
  overall_rate: {
    display: 'inline-block',
    width: '25px',
    height: '25px',
    marginLeft: '5px',
    padding: '4px 10px 2px',
    backgroundColor: '#e8e8e8',
    color: '#000',
    fontWeight: 'bold',
    textAlign: 'center',
    lineHeight: '27px',
    fontSize: '18px'
  }
}))
