import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { useTranslation } from 'react-i18next'

import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import { withStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'

import Button from '../inputs/Button'
import ManageUser from './ManageUser'
import Users from './Users'
import { settingsTabs } from '../constant'
import SubmitSetup from '../competition-info/submission'
import TabsWithIframeLinks from './TabsWithIframeLinks'
import useCollection from '../competition-info/submission/hooks/useCollection'
import { Typography } from '@material-ui/core'
import ManageRatingCriteria from './RatingCriteria'

const styles = theme => ({
  root: {
    padding: '24px',
    margin: 0,
    flex: 1,
    display: 'flex',
    flexDirection: 'column'
  },
  tab: {
    fontSize: '1rem',
    fontFamily: 'HarmoniaSansStd !important',
    padding: 0,
    textAlign: 'left',
    minWidth: 270,
    minHeight: 32,
    marginBottom: 8,
    marginRight: 16,
    backgroundColor: theme.modeColors.tabBackground
  },
  tabLabel: {
    padding: '4px 16px',
    alignItems: 'start'
  },
  selected: {
    backgroundColor: theme.modeColors.selectedTabBackground,
    fontWeight: 700
  },
  tabContainer: {
    padding: '24px 32px',
    backgroundColor: theme.modeColors.navBackground,
    flex: 1,
    position: 'relative',
    // zIndex: 2,
    marginBottom: 30
  },
  tabContainerWithoutPadding: {
    flex: 1,
    backgroundColor: theme.modeColors.navBackground,
    // backgroundColor: 'lightgreen',
    display: 'flex',
    flexDirection: 'column'
  },
  lightThemeContainer: {
    backgroundColor: '#f7f8f9'
  },
  darkThemeContainer: {
    backgroundColor: '#000000',
    color: '#f7f8f9'
  },
  textModeColors: {
    color: theme.modeColors.proposalTitle
  }
})

const VerticalTabs = withStyles(theme => ({
  flexContainer: {
    flexDirection: 'column'
  },
  indicator: {
    display: 'none'
  }
}))(Tabs)

function Settings({ profile, history, classes, clicked, themeMode }) {
  const { documents } = useCollection(
    'TabsWithIframeLinks',
    'availableAt',
    'settings'
  )
  const [activeSettingsTab, setActiveSettingsTab] = useState('users')
  const { t } = useTranslation('proposals')

  useEffect(() => {
    if (profile && profile.role === 'judge') {
      history.push('/')
    }
  }, [profile, history])

  const handleChange = (_, tabValue) => setActiveSettingsTab(tabValue)

  const shapeTabLabelsData = () => {
    if (!documents) {
      return [...settingsTabs]
    }

    const extraTabsLabels = Object.values(documents).map(document => ({
      id: document?.id,
      label: document?.label,
      value: document?.label
        .toLowerCase()
        .split(' ')
        .join('-')
    }))
    return [...settingsTabs, ...extraTabsLabels]
  }

  const getExtraTabData = () => {
    if (!documents) return

    const document = Object.values(documents).find(
      document => document.value === activeSettingsTab
    )
    return document
  }

  const tabsLabels = shapeTabLabelsData()
  const extraTabData = getExtraTabData()

  return (
    <div className={classes.root}>
      <h1 className={`mb-3 ${classes.textModeColors}`}>{t('Settings')} </h1>
      <div className="d-flex" style={{ flex: 1 }}>
        <VerticalTabs value={activeSettingsTab} onChange={handleChange}>
          {tabsLabels.map(({ id, label, value }) => {
            return (
              <Tab
                key={id}
                label={t(label)}
                classes={{
                  root: classes.tab,
                  selected: classes.selected,
                  wrapper: classes.tabLabel
                }}
                value={value}
              />
            )
          })}
        </VerticalTabs>

        {(() => {
          switch (activeSettingsTab) {
            case 'users':
              return <UsersTable t={t} classes={classes} />
            case 'submit-setup':
              return (
                <div className={classes.tabContainer}>
                  <h3 className="mb-0 pb-1">{t('Submit setup')}</h3>
                  <SubmitSetup />
                </div>
              )
            case 'tab-setup':
              return (
                <div className={classes.tabContainer}>
                  <h3 className="mb-0 pb-1">{t('Tab setup')}</h3>
                  <TabsWithIframeLinks />
                </div>
              )
            case 'rating-criteria':
              return (
                <div className={classes.tabContainer}>
                  <h3 className="mb-2 pb-1">{t('Rating criteria')}</h3>
                  <ManageRatingCriteria />
                </div>
              )
            case 'theme':
              return (
                <Theme
                  t={t}
                  classes={classes}
                  clicked={clicked}
                  themeMode={themeMode}
                />
              )
            default:
              return (
                <div className={classes.tabContainerWithoutPadding}>
                  {!!extraTabData.isIframeLink ? (
                    <IframeComponent
                      iframeTitle={extraTabData?.label}
                      iframeLink={extraTabData?.iframeLink}
                      pageHeight={extraTabData?.pageHeight}
                    />
                  ) : (
                    <div
                      style={{ height: extraTabData?.pageHeight }}
                      dangerouslySetInnerHTML={{
                        __html: extraTabData?.html
                      }}
                    />
                  )}
                </div>
              )
          }
        })()}
      </div>
      {/* {isModalOpen && <ManageUser close={closeModal} isOpen={isModalOpen} />} */}
    </div>
  )
}

const mapStateToProps = state => {
  return {
    profile: state.firebase.profile
  }
}

export default connect(mapStateToProps, null)(withStyles(styles)(Settings))

function UsersTable({ t, classes }) {
  return (
    <div className={classes.tabContainer}>
      <div className="d-flex align-items-center justify-content-between mb-2">
        <h3 className="mb-0 pb-1">{t('Users')} </h3>
        <ManageUser>
          <Button size="md" variant="contained" color="primary">
            + {t('Add a user')}
          </Button>
        </ManageUser>
      </div>

      <Grid container className="border-bottom py-1">
        <Grid item xs={3} className="h5 mb-0">
          {t('Name')}
        </Grid>
        <Grid item xs={3} className="sm">
          {t('Email')}
        </Grid>
        <Grid item xs={2} className="sm">
          {t('Profession')}
        </Grid>
        <Grid item xs={1} className="sm">
          {t('Role')}
        </Grid>
        <Grid item xs={2} className="sm">
          <Typography align="center">{t('Rate factor')}</Typography>
        </Grid>
        <Grid item xs={1} className="sm"></Grid>
      </Grid>
      <Users />
    </div>
  )
}

function Theme({ t, classes, themeMode, clicked }) {
  return (
    <div className={classes.tabContainer}>
      <div className="d-flex align-items-center justify-content-between mb-2">
        <h3 className="mb-0 pb-1">{t('Toggle theme')} </h3>
      </div>

      <div>
        <div
          className={`${classes.lightThemeContainer} px-2 py-1 mb-2 d-flex align-items-center justify-content-between`}
          style={{ width: '100%', maxWidth: '400px' }}
        >
          <p>{t('Light theme')}</p>
          <Button
            onClick={() => {
              clicked('light')
            }}
            size="md"
            variant="contained"
            color={themeMode === 'light' ? 'primary' : 'default'}
          >
            {themeMode === 'light' ? t('Activated') : t('Activate')}
          </Button>
        </div>

        <div
          className={`${classes.darkThemeContainer} px-2 py-1 d-flex align-items-center justify-content-between`}
          onClick={() => {
            clicked('dark')
          }}
          style={{ width: '100%', maxWidth: '400px' }}
        >
          <p>{t('Dark theme')}</p>
          <Button
            onClick={() => {
              clicked('light')
            }}
            size="md"
            variant="contained"
            color={themeMode !== 'light' ? 'primary' : 'default'}
          >
            {themeMode !== 'light' ? t('Activated') : t('Activate')}
          </Button>
        </div>
      </div>
    </div>
  )
}

function IframeComponent({ iframeLink, iframeTitle, pageHeight }) {
  return (
    <div style={{ height: pageHeight }}>
      <iframe
        id={iframeTitle}
        title={iframeTitle}
        src={iframeLink}
        width="100%"
        height="100%"
        loading="eager"
        frameBorder="0"
        scrolling="no"
      />
    </div>
  )
}
