import React from 'react'
import styled from 'styled-components'
import { Bubble } from 'react-chartjs-2'
import { compose } from 'redux'
import { firestoreConnect } from 'react-redux-firebase'
import { connect } from 'react-redux'
import { useTranslation } from 'react-i18next'
import moment from 'moment-timezone'

function uuidToRgbaColor(uuid) {
  // Extract the first 6 characters after removing the dashes
  const hex = uuid.replace(/-/g, '').substring(0, 6)
  return `rgba(${parseInt(hex.substring(0, 2), 16)}, ${parseInt(
    hex.substring(2, 4),
    16
  )}, ${parseInt(hex.substring(4, 6), 16)}, 0.3)`
}

function countTotalProposalsByStatus(proposals, status) {
  return proposals.filter(proposal => proposal.proposalStatus === status).length
}

function replaceNotStandardTimezone(time) {
  if (time.includes('OEZ')) {
    return time.replace(' OEZ', ' EET')
  }
  return time
}

function SubmissionPlotChartForTwoDeadlineDates(props) {
  const { t } = useTranslation('proposals')
  // const currentLanguage = i18n.language

  if (!props['sub-b-proposals']) return <div />

  const totalIncompleteProposals = countTotalProposalsByStatus(
    props['sub-b-proposals'],
    'keskeneräinen'
  )
  const totalCompleteProposals = countTotalProposalsByStatus(
    props['sub-b-proposals'],
    'valmis'
  )

  // const filteredProposals = props['sub-b-proposals'].filter(proposal =>
  //   proposal.history.some(history => {
  //     const time = replaceNotStandardTimezone(history.timeStamp)
  //     const date = moment
  //       .tz(time, 'YYYY-MM-DD HH:mm:ss Z', 'Europe/Helsinki')
  //       .format('YYYY-MM-DD')
  //     return date === '2024-01-15' || date === '2024-01-16'
  //   })
  // )
  // console.log('filteredProposals:', filteredProposals)

  const datasets = props['sub-b-proposals'].map((proposal, index) => {
    let borderColor = ''
    let borderWidth = 0
    return {
      label: `${index + 1}`,
      data: proposal.history
        .filter(history => {
          const time = replaceNotStandardTimezone(history.timeStamp)
          const date = moment
            .tz(time, 'YYYY-MM-DD HH:mm:ss Z', 'Europe/Helsinki')
            .format('YYYY-MM-DD')
          return date === '2024-03-07' || date === '2024-03-08'
        })
        .map((history, index) => {
          borderColor =
            history.status === 'keskeneräinen' ? '#f44336' : '#2196f3'
          borderWidth = history.status === 'keskeneräinen' ? 1 : 1.5
          const time = replaceNotStandardTimezone(history.timeStamp)
          const date = moment.tz(
            time,
            'YYYY-MM-DD HH:mm:ss Z',
            'Europe/Helsinki'
          )
          const hours =
            date.format('YYYY-MM-DD') === '2024-03-08'
              ? date.hours() + 24
              : date.hours()
          const minutes = date.minutes()

          return {
            x: hours,
            y: minutes,
            r: 4 + (20 / Math.log(20)) * Math.log(index + 1.5)
          }
        }),
      backgroundColor: uuidToRgbaColor(proposal.id),
      borderColor,
      borderWidth
    }
  })

  const data = {
    datasets
  }

  const options = {
    maintainAspectRatio: false,
    aspectRatio: 1,
    legend: {
      position: 'bottom'
    },
    scales: {
      xAxes: [
        {
          type: 'linear',
          ticks: {
            min: 0,
            max: 37,
            stepSize: 1
          },
          gridLines: {
            display: false
          }
        }
      ],
      yAxes: [
        {
          type: 'linear',
          ticks: {
            min: 0,
            max: 60,
            stepSize: 1
          },
          gridLines: {
            display: false
          }
        }
      ]
    }
  }

  // Calculate the height based on the number of proposals
  const chartHeight = `${data.datasets.length * 2 + 600}px` // Adjust the multiplier as needed

  console.log('data', data)

  return (
    <Container>
      <h3>{t('Deadline Bubble Chart for the End Dates (D-2 days)')}</h3>
      <Description>
        <span>x={t('hours')}</span>
        <span>y={t('minutes')}</span>
        <span>{`${t('size')}=${t('proposal editing rounds')}`}</span>
        <SubDescription>
          <span className="text-red">{`${totalIncompleteProposals} ${t(
            'incomplete'
          )}`}</span>
          <span>{t('and')}</span>
          <span className="text-blue">{`${totalCompleteProposals} ${t(
            'complete'
          )}`}</span>
          <span>{t('submissions')}</span>
        </SubDescription>
      </Description>
      <div
        style={{
          width: '100%',
          height: chartHeight,
          overflow: 'auto',
          position: 'relative'
        }}
      >
        <Bubble options={options} data={data} />
      </div>
    </Container>
  )
}

const mapStateToProps = state => {
  return {
    'sub-b-proposals': state.firestore.ordered['sub-b-proposals']
  }
}

export default compose(
  connect(mapStateToProps),
  firestoreConnect([
    { collection: 'sub-b-proposals', orderBy: ['createdAt', 'asc'] }
  ])
)(SubmissionPlotChartForTwoDeadlineDates)

const Container = styled.div`
  padding: 32px;
  flex: 1;
  user-select: text;
  background-color: #fff;
  position: relative;

  h3 {
    margin-bottom: 7px;
  }
`

const Description = styled.div`
  font-size: 16px;
  line-height: 24px;
  margin: 0;
  margin-bottom: 16px;
  display: flex;
  align-items: center;
  gap: 16px;
`

const SubDescription = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;

  .text-red {
    color: #f44336;
  }

  .text-blue {
    color: #2196f3;
  }
`
