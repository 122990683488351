import React from 'react'

import { withStyles } from '@material-ui/core/styles'
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import IconButton from '@material-ui/core/IconButton'
import CloseIcon from '@material-ui/icons/Close'

import ManageRatingReduxForm from './ManageRatingForm'

const styles = theme => ({
  dialogPaper: {
    borderRadius: 0,
    backgroundColor: theme.modeColors.inputBackground,
    width: '500px'
  },
  dialogTitle: {
    borderBottom: '1px solid #e0e2e4',
    margin: 0,
    padding: '20px 16px 18px'
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: '#353c43'
  }
})

function ManageRatingCriteria({ criteria, criteriaItem, classes, children }) {
  const [isModalOpen, setIsModalOpen] = React.useState(false)

  const openModal = () => setIsModalOpen(true)

  const closeModal = () => setIsModalOpen(false)

  return (
    <>
      <div onClick={openModal}>{children}</div>
      <Dialog
        aria-labelledby="customized-dialog-title"
        open={isModalOpen}
        maxWidth="sm"
        classes={{ paper: classes.dialogPaper }}
      >
        <DialogTitle disableTypography className={classes.dialogTitle}>
          <h3 className="mb-0">Manage rating criteria</h3>
          <IconButton
            aria-label="Close"
            className={classes.closeButton}
            onClick={closeModal}
          >
            <CloseIcon fontSize="small" />
          </IconButton>
        </DialogTitle>

        <ManageRatingReduxForm
          criteria={criteria}
          criteriaItem={criteriaItem}
          closeModal={closeModal}
        />
      </Dialog>
    </>
  )
}

export default withStyles(styles)(ManageRatingCriteria)
