import React from 'react'
import styled from 'styled-components'
import Comment from '@material-ui/icons/Comment'

import useCollection from '../../competition-info/submission/hooks/useCollection'

export default function TotalComments({ proposalId, style }) {
  const [comments, setComments] = React.useState(0)
  const { documents } = useCollection('comments')

  React.useEffect(() => {
    if (documents) {
      if (!proposalId) return setComments(Object.values(documents).length)
      const totalComments = Object.values(documents).filter(
        doc => doc.proposalId === proposalId
      ).length
      return setComments(totalComments)
    }
    return setComments(0)
  }, [documents, proposalId])

  if (!comments) return null

  return (
    <Wrapper style={style}>
      <CommentIcon />
      <StyledSpan>{comments}</StyledSpan>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: -9px;
  padding: 3px 5px;
  background-color: rgb(3 83 151);
  color: #fff;
  font-size: 16px;
  font-weight: 700;
  position: relative;
`

const StyledSpan = styled.span`
  /* margin-bottom: -2px; */
  font-weight: 700;
  font-size: 14px;
`

const CommentIcon = styled(Comment)`
  width: 15px;
  height: 15px;
  margin-right: 3px;
  fill: white;
`
