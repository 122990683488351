import React from 'react'
import styled from 'styled-components'
import { Bubble } from 'react-chartjs-2'
import { compose } from 'redux'
import { firestoreConnect } from 'react-redux-firebase'
import { connect } from 'react-redux'
import { useTranslation } from 'react-i18next'
import moment from 'moment-timezone'

function uuidToRgbaColor(uuid) {
  // Extract the first 6 characters after removing the dashes
  const hex = uuid.replace(/-/g, '').substring(0, 6)
  return `rgba(${parseInt(hex.substring(0, 2), 16)}, ${parseInt(
    hex.substring(2, 4),
    16
  )}, ${parseInt(hex.substring(4, 6), 16)}, 0.3)`
}

function countTotalProposalsByStatus(proposals, status) {
  return proposals.filter(proposal => proposal.proposalStatus === status).length
}

function replaceNotStandardTimezone(time) {
  if (time.includes('OEZ')) {
    return time.replace(' OEZ', ' EET')
  }
  return time
}

function SubmissionPlotChart(props) {
  const { t, i18n } = useTranslation('proposals')
  const currentLanguage = i18n.language

  if (!props['sub-b-proposals']) return <div />

  const totalIncompleteProposals = countTotalProposalsByStatus(
    props['sub-b-proposals'],
    'keskeneräinen'
  )
  const totalCompleteProposals = countTotalProposalsByStatus(
    props['sub-b-proposals'],
    'valmis'
  )

  const datasets = props['sub-b-proposals'].map((proposal, index) => {
    let borderColor = ''
    let borderWidth = 0
    return {
      label: `${index + 1}`,
      data: proposal.history.map((history, index) => {
        borderColor = history.status === 'keskeneräinen' ? '#f44336' : '#2196f3'
        borderWidth = history.status === 'keskeneräinen' ? 1 : 1.5
        const time = replaceNotStandardTimezone(history.timeStamp)
        const date = moment.tz(time, 'YYYY-MM-DD HH:mm:ss Z', 'Europe/Helsinki')
        const hours = date
          .hours()
          .toString()
          .padStart(2, '0')
        const minutes = date
          .minutes()
          .toString()
          .padStart(2, '0')
        const seconds = date
          .seconds()
          .toString()
          .padStart(2, '0')

        return {
          x: date.toDate(), // Convert the moment object back to a JavaScript Date
          y: `${hours}:${minutes}:${seconds}`,
          r: 4 + (20 / Math.log(20)) * Math.log(index + 1.5)
        }
      }),
      backgroundColor: uuidToRgbaColor(proposal.id),
      borderColor,
      borderWidth
    }
  })

  const data = {
    datasets
  }

  const options = {
    maintainAspectRatio: false,
    aspectRatio: 1,
    legend: {
      position: 'bottom'
    },
    scales: {
      xAxes: [
        {
          type: 'time',
          distribution: 'linear',
          time: {
            unit: 'day',
            min: new Date('2024-02-08'),
            // min: new Date(Date.now() - 30 * 24 * 60 * 60 * 1000), // Set the min value to the lowest from data
            max: new Date('2024-03-08') // Set the max value to the current time
          },
          ticks: {
            callback: function(value) {
              if (value === 'Invalid date') return ''
              const dateLocale = currentLanguage === 'fi' ? 'fi-FI' : 'en-GB'
              return new Date(value).toLocaleDateString(dateLocale, {
                day: 'numeric',
                month: 'short'
              })
            },
            min: new Date('2024-02-08'),
            // min: new Date(Date.now() - 30 * 24 * 60 * 60 * 1000), // Set the min value to 30 days ago
            max: new Date('2024-03-08') // Set the max value to the current time,
          },
          gridLines: {
            display: false
          }
        }
      ],
      yAxes: [
        {
          type: 'time',
          time: {
            parser: 'HH:mm:ss',
            unit: 'hour',
            displayFormats: {
              hour: 'HH:mm'
            },
            min: '00:00:00',
            max: '24:00:00'
          },
          ticks: {
            min: '00:00:00',
            max: '24:00:00'
          },
          gridLines: {
            display: false
          }
        }
      ]
    }
  }

  // Calculate the height based on the number of proposals
  const chartHeight = `${data.datasets.length * 2 + 600}px` // Adjust the multiplier as needed

  return (
    <Container>
      <h3>{t('SUBMISSION BUBBLE CHART')}</h3>
      <Description>
        <span>x={t('date')}</span>
        <span>y={t('hour')}</span>
        <span>{`${t('size')}=${t('proposal editing rounds')}`}</span>
        <SubDescription>
          <span className="text-red">{`${totalIncompleteProposals} ${t(
            'incomplete'
          )}`}</span>
          <span>{t('and')}</span>
          <span className="text-blue">{`${totalCompleteProposals} ${t(
            'complete'
          )}`}</span>
          <span>{t('submissions')}</span>
        </SubDescription>
      </Description>
      <div
        style={{
          width: '100%',
          height: chartHeight,
          overflow: 'auto',
          position: 'relative'
        }}
      >
        <Bubble options={options} data={data} />
      </div>
    </Container>
  )
}

const mapStateToProps = state => {
  return {
    'sub-b-proposals': state.firestore.ordered['sub-b-proposals']
  }
}

export default compose(
  connect(mapStateToProps),
  firestoreConnect([
    { collection: 'sub-b-proposals', orderBy: ['createdAt', 'asc'] }
  ])
)(SubmissionPlotChart)

const Container = styled.div`
  padding: 32px;
  flex: 1;
  user-select: text;
  background-color: #fff;
  position: relative;

  h3 {
    margin-bottom: 7px;
  }
`

const Description = styled.div`
  font-size: 16px;
  line-height: 24px;
  margin: 0;
  margin-bottom: 16px;
  display: flex;
  align-items: center;
  gap: 16px;
`

const SubDescription = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;

  .text-red {
    color: #f44336;
  }

  .text-blue {
    color: #2196f3;
  }
`
